<template>
  <div id="sale-genqr-page">
    <div class="grid grid-cols-12 pt-10">
      <div
        v-for="inp in regisInformation"
        :key="inp.id"
        :class="{
          'col-span-12 col-start-1 col-end-13': inp.span,
          'col-span-6 col-start-1 col-end-7':
            !inp.span &&
            (inp.id === 'firstname' ||
              inp.id === 'phone' ||
              inp.id === 'startTime'),
          'col-span-6 col-start-7 col-end-13':
            !inp.span &&
            (inp.id === 'lastname' ||
              inp.id === 'email' ||
              inp.id === 'endTime'),
          'p-2': true,
        }"
      >
        <!-- <div v-if="inp.typeInput === 'input'"> -->
        <InputNdbx
          v-if="inp.typeInput === 'input'"
          :id="inp.id"
          :label="inp.label"
          :type="inp.type"
          :readonly="inp.readonly ? inp.readonly : false"
          :classInvalid="inp.classInvalid"
          :errorMessage="
            inp.errorMessage ? inp.errorMessage : 'ต้องไม่เป็นค่าว่าง'
          "
          v-model="inp.value"
        />

        <!-- {{ inp.typeInput }} -->
        <SelectNdbx
          v-else-if="inp.typeInput === 'select'"
          :id="inp.id"
          :label="inp.label"
          v-model="inp.value"
          :options="inp.options"
          :classInvalid="inp.classInvalid"
        />

        <!-- </div> -->
      </div>
      <button
        class="border-ra rounded p-2 bg-green-400 hover:bg-green-500 text-white mt-10 col-start-5 col-end-9 sm:col-start-6 sm:col-end-8"
        @click="actionGenqr"
      >
        บันทึก
      </button>
    </div>
  </div>
</template>
<script>
import InputNdbx from "@/components/ndbx-elements/Input.vue";
import SelectNdbx from "@/components/ndbx-elements/Select.vue";
var moment = require("moment");

export default {
  name: "SaleGenQrPage",
  components: {
    InputNdbx,
    SelectNdbx,
  },
  data() {
    return {
      addressNo: "",
      regisInformation: [
        {
          id: "firstname",
          label: "ชื่อ",
          type: "text",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
        },
        {
          id: "lastname",
          label: "นามสกุล",
          type: "text",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
        },
        {
          id: "project",
          label: "โครงการ",
          type: "",
          value: "",
          typeInput: "input",
          span: true,
          readonly: true,
          classInvalid: true,
        },
        {
          id: "note",
          label: "ติดต่อเรื่อง",
          type: "",
          value: "",
          typeInput: "select",
          options: [
            { id: 1, value: "", name: "กรุณาเลือกเรื่องที่ต้องการติดต่อ" },
          ],
          span: true,
          classInvalid: true,
        },
        {
          id: "phone",
          label: "เบอร์โทร",
          type: "text",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
          errorMessage: "",
        },
        {
          id: "email",
          label: "อีเมล",
          type: "text",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
        },
        {
          id: "date",
          label: "วันที่",
          type: "date",
          value: "",
          typeInput: "input",
          span: true,
          classInvalid: true,
        },
        {
          id: "startTime",
          label: "เวลาเริ่มต้น",
          type: "time",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
        },
        {
          id: "endTime",
          label: "เวลาสิ้นสุด",
          type: "time",
          value: "",
          typeInput: "input",
          span: false,
          classInvalid: true,
        },
      ],
    };
  },
  async created() {
    this.regisInformation[2].value = localStorage.getItem("projectName");
    this.addressNo = `${localStorage.getItem("addressNo")}/sale`;

    const data = await this.$store.dispatch("sale/actionGetContactSale");

    if (data) {
      data.forEach((element) => {
        this.regisInformation[3].options.push({
          id: element.id + 1,
          value: element.contact,
          name: element.contact,
        });
      });
    }
  },
  computed: {
    inpFirstName() {
      return this.regisInformation[0].value;
    },
    inpLastName() {
      return this.regisInformation[1].value;
    },
    inpContactMatter() {
      return this.regisInformation[3].value;
    },
    inpPhone() {
      return this.regisInformation[4].value;
    },
    inpEmail() {
      return this.regisInformation[5].value;
    },
    inpAppointmentDate() {
      return this.regisInformation[6].value;
    },
    inpAppointmentStart() {
      return this.regisInformation[7].value;
    },
    inpAppointmentEnd() {
      return this.regisInformation[8].value;
    },
  },
  watch: {
    inpFirstName(newVal) {
      if (newVal != "") {
        if (this.regisInformation[0].classInvalid == false) {
          this.regisInformation[0].classInvalid = true;
        }
      }
    },
    inpLastName(newVal) {
      if (newVal != "") {
        if (this.regisInformation[1].classInvalid == false) {
          this.regisInformation[1].classInvalid = true;
        }
      }
    },
    inpContactMatter(newVal) {
      if (newVal != "") {
        if (this.regisInformation[3].classInvalid == false) {
          this.regisInformation[3].classInvalid = true;
        }
      }
    },
    inpPhone(newVal) {
      if (newVal != "") {
        // if (this.regisInformation[4].classInvalid == false) {
        //   this.regisInformation[4].classInvalid = true;
        // }
        if (newVal.length != 10) {
          console.log("true");
          this.regisInformation[4].classInvalid = false;
          this.regisInformation[4].errorMessage =
            "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก";
        } else {
          console.log("false");
          this.regisInformation[4].classInvalid = true;
          this.regisInformation[4].errorMessage = "";
        }
      }
    },
    inpEmail(newVal) {
      if (newVal != "") {
        const resultEmail = this.validateEmail(newVal);
        if (newVal != "" && resultEmail) {
          if (this.regisInformation[5].classInvalid == false) {
            this.regisInformation[5].classInvalid = true;
          }
        } else {
          this.regisInformation[5].classInvalid = false;
          this.regisInformation[5].errorMessage = "กรุณากรอกอีเมลให้ถูกต้อง";
        }
      }
    },
    inpAppointmentDate(newVal) {
      if (newVal != "") {
        if (this.regisInformation[6].classInvalid == false) {
          this.regisInformation[6].classInvalid = true;
        }
      }
    },
    inpAppointmentStart(newVal) {
      if (newVal != "" && !newVal.includes("HH") && !newVal.includes("mm")) {
        if (this.regisInformation[7].classInvalid == false) {
          this.regisInformation[7].classInvalid = true;
        }
      } else {
        this.regisInformation[7].classInvalid = false;
        this.regisInformation[7].errorMessage = "กรุณาตรวจสอบเวลาเริ่มต้น";
      }
    },
    inpAppointmentEnd(newVal) {
      if (newVal != "" && !newVal.includes("HH") && !newVal.includes("mm")) {
        const startTime = moment(this.regisInformation[7].value, "HH:mm");
        const endTime = moment(newVal, "HH:mm");

        if (startTime.isBefore(endTime)) {
          if (this.regisInformation[8].classInvalid == false) {
            this.regisInformation[8].classInvalid = true;
          }
        } else {
          this.regisInformation[8].classInvalid = false;
          this.regisInformation[8].errorMessage = "กรุณาตรวจสอบเวลาสิ้นสุด";
        }
      } else {
        this.regisInformation[8].classInvalid = false;
        this.regisInformation[8].errorMessage = "กรุณาตรวจสอบเวลาสิ้นสุด";
      }
    },
  },
  methods: {
    async actionGenqr() {
      var errors = [];

      this.regisInformation.forEach((element, index) => {
        if (element.value == "") {
          const inpData = {
            index: index,
            id: element.id,
          };
          errors.push(inpData);
        }
      });

      if (errors.length === 0) {
        const loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          lockScroll: true,
          zIndex: 9999,
          loader: "dots",
          color: "#006192",
        });
        const params = {
          first_name: this.regisInformation[0].value,
          last_name: this.regisInformation[1].value,
          project_code: await this.$store.getters["login/getProjectCode"],
          contact_matter: this.regisInformation[3].value,
          mobile: this.regisInformation[4].value,
          email: this.regisInformation[5].value,
          appointment_date: this.regisInformation[6].value,
          appointment_start: this.regisInformation[7].value,
          appointment_end: this.regisInformation[8].value,
          visitor_type: "9",
          room_number: this.addressNo,
        };
        console.log(params);
        // const params = {
        //   first_name: "test",
        //   last_name: "test",
        //   project_code: localStorage.getItem("projectCode"),
        //   contact_matter: "เยื่ยมชมโครงการ",
        //   mobile: "0999999999",
        //   email: "nu@gmail.com",
        //   appointment_date: "2022-11-03",
        //   appointment_start: "19:00",
        //   appointment_end: "20:00",
        //   visitor_type: "9",
        //   room_number: "12/sale",
        // };

        const data = await this.$store.dispatch(
          "sale/actionCreateQrcode",
          params
        );
        if (data) {
          var qs = require("qs");
          data.project_code = localStorage.getItem("projectCode");
          localStorage.setItem("paramsAppointment", qs.stringify(data));

          loader.hide();

          this.$router.push({
            name: "sale.preview",
          });
        }
      } else {
        console.log("validation :: Invalid");
        console.log(errors);
        errors.forEach((element) => {
          // const inp = document.getElementById(`${element}`);
          this.regisInformation[element.index].classInvalid = false;
        });

        document.getElementById(errors[0].id).focus();
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(String(email).toLowerCase());
    },
  },
};
</script>
