<template>
  <div>
    <div class="container">
      <span class="block text-sm text-left font-medium text-slate-700">{{
        label
      }}</span>

      <select
        :id="id"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :readonly="readonly"
        :class="{
          'border-2 rounded px-2 h-8 w-full hover:border-blue-400':
            classInvalid,
          'has-error border-2 rounded px-2 h-8 w-full border-red-500 focus:outline-none focus:ring focus:ring-red-300':
            !classInvalid,
        }"
      >
        <option
          v-for="option in options"
          :key="option.id"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
      <span
        v-if="!classInvalid"
        class="block text-sm text-red-500 pt-2 text-left font-medium text-slate-700"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
      note: "Id for the input",
    },
    label: {
      type: String,
      required: false,
      note: "Label for the input",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      note: "Label for the input",
    },
    modelValue: {
      type: [String, Number, Date],
      default: "",
      note: "Value for the input",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: "array options",
    },
    classInvalid: {
      type: Boolean,
      required: false,
      default: true,
      note: "Label for the input",
    },
  },
  data() {
    return {
      errorMessage: "ต้องไม่เป็นค่าว่าง",
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
